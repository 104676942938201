import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { Form, FormContainer } from "../../../components/form/Form";
import { NumberInput } from "../../../components/form/NumberInput";
import { TextArea } from "../../../components/form/TextArea";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Products } from "../../../components/products/Products";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { getIntlNumberFormat } from "../../../components/utils";
import { FinancialData, dataFinancial } from "../../../data/dataFinancial";
import { Associate, Contract, dataMerchant } from "../../../data/dataMerchant";
import { useCountry } from "../../../hooks/useCountry";
import { useLinkId } from "../../../hooks/useLinkId";
import { CurrencyByCountry } from "../../../i18n";
import { contractState } from "../../../state/contractState";
import { EstimatedRangeFields } from "./EstimatedRangeFields";
import { default as lodashEqual } from "lodash.isequal";
import {
  Address,
  Country,
  Language,
  ProductType,
} from "../../../data/models/ContractTypes";
import { ButtonPaneWithLabel } from "../../../components/interactions/Buttons/ButtonPaneInput";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";
import { Section } from "../../../components/section/Section";
import { TextInput } from "../../../components/form/TextInput";
import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { dataAssociates } from "../../../data/dataAssociates";
import { useSuspendedQueries } from "../../../hooks/useSuspendedQueries";
import { InvoiceToggle } from "./InvoiceAddress/InvoiceToggle";
import { routeState } from "../../../state/routeState";
import { Access } from "../../../data/proxy";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { InfoBox } from "../../../components/boxes/InfoBox";
import styles from "./Financials.module.scss";
import { Bax } from "./Bax";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { Dynamic } from "../../../components/animate/Dynamic";
import { getHint, getMaxValidator, getMinValidator } from "./validationUtils";
import { Psp, dataBusinessRisk } from "../../../data/dataBusinessRisk";
import { Tof } from "./Tof";
import { AmexAndResurs } from "./AmexAndResurs";

export enum InvoiceEmailStatus {
  REQUEST_CODE = "request-code",
  CONFIRM_CODE = "confirm-code",
  CONFIRMED_AS_PRIMARY = "confirmed-as-primary",
  CONFIRMED_WITH_CODE = "confirmed-with-code",
  PENDING = "pending",
  ERROR = "error",
}

const WORLDLINE_PSP = "Worldline Device (Samport)";

export function allowForBax(contract: Contract, businessRisk: Psp) {
  if (Country.NORWAY !== contract.contractData.country) {
    return false;
  }

  if (
    contract.productType === ProductType.BAMBORA_ONE ||
    contract.productType === ProductType.BAMBORA_ONE_SHORT_TERM
  ) {
    return true;
  }

  if (contract.productType !== ProductType.ACCEPTANCE_INSTORE) {
    return false;
  }

  if (businessRisk.psp === WORLDLINE_PSP) {
    return false;
  }

  return true;
}

export function allowForTof(contract: Contract, businessRisk: Psp) {
  if (Country.DENMARK !== contract.contractData.country) {
    return false;
  }

  if (
    contract.productType !== ProductType.ACCEPTANCE_INSTORE &&
    contract.productType !== ProductType.ACCEPTANCE_ONLINE
  ) {
    return false;
  }

  if (businessRisk.psp === WORLDLINE_PSP) {
    return false;
  }

  return true;
}

export enum InvoiceType {
  EMAIL = "email",
  ADDRESS = "address",
}

export interface FinancialSave
  extends Omit<FinancialData, "thirdPartyPayments"> {
  thirdPartyPayments?: boolean;
}

function shouldSaveInvoice(productType: ProductType) {
  return (
    productType === ProductType.BAMBORA_DEVICE ||
    productType === ProductType.BAMBORA_DEVICE_SHORT_TERM ||
    productType === ProductType.BAMBORA_ONE ||
    productType === ProductType.BAMBORA_ONE_SHORT_TERM
  );
}

function getInitialInvoiceType(contract: Contract): InvoiceType | undefined {
  if (contract.contractData.invoiceEmail) {
    return InvoiceType.EMAIL;
  }

  if (!!contract.contractData.invoiceAddress?.street) {
    return InvoiceType.ADDRESS;
  }
}

function getInitialStatus(contract: Contract, primary?: Associate) {
  const { invoiceEmail } = contract.contractData;
  if (!invoiceEmail) {
    // Yes, always default to primary email
    return InvoiceEmailStatus.CONFIRMED_AS_PRIMARY;
  }

  if (!primary) {
    // Something is fishy, we should always have a primary
    return InvoiceEmailStatus.REQUEST_CODE;
  }

  if (invoiceEmail === primary.contact.email) {
    return InvoiceEmailStatus.CONFIRMED_AS_PRIMARY;
  }

  return InvoiceEmailStatus.CONFIRMED_WITH_CODE;
}

const defaultAddress = {
  street: "",
  postalCode: "",
  city: "",
  countryCode: undefined,
};

const MAX_LENGTH_DESCRIPTION = 255;
enum IntegrationType {
  BAX = "bax",
  TOF = "tof",
  AMEX = "amex",
  RESURS = "resurs",
}

interface IntegrationSettings {
  [IntegrationType.BAX]: {
    enabled: boolean;
    value: string;
  };
  [IntegrationType.TOF]: {
    enabled: boolean;
    value: string;
  };
  [IntegrationType.AMEX]: {
    enabled: boolean;
    value: string;
  };
  [IntegrationType.RESURS]: {
    enabled: boolean;
    value: string;
  };
}

function getValuesFromIntegrations(integrations: IntegrationSettings) {
  return {
    bax: integrations.bax.enabled ? integrations.bax.value : "",
    tof: integrations.tof.enabled ? integrations.tof.value : "",
    amex: integrations.amex.enabled ? integrations.amex.value : "",
    resurs: integrations.resurs.enabled ? integrations.resurs.value : "",
  };
}

export const Financials: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const country = useCountry();
  const formContainer = useRef<FormContainer>();
  const { access } = useRecoilValue(routeState);
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const [contract, setContract] = useRecoilState(contractState);
  const [code, setCode] = useState<string>("");
  const [codeWarning, setCodeWarning] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const latestVerifiedInvoiceEmail = useRef<string>(
    contract.contractData.invoiceEmail || ""
  );

  const [{ data: response }, { data: associates }, { data: businessRisk }] =
    useSuspendedQueries<any>({
      queries: [
        dataFinancial(access).fetchFinancial(linkId),
        dataAssociates(access).fetchAssociates(linkId),
        dataBusinessRisk(access).fetchBusinessRiskData(linkId),
      ],
    });

  const [integrations, setIntegrations] = useState<IntegrationSettings>({
    [IntegrationType.BAX]: {
      enabled: allowForBax(contract, businessRisk)
        ? !!businessRisk.bax ||
          contract.productType === ProductType.ACCEPTANCE_INSTORE
        : false,
      value: businessRisk.bax ?? "",
    },
    [IntegrationType.TOF]: {
      enabled: allowForTof(contract, businessRisk),
      value: businessRisk.tof ?? "",
    },
    [IntegrationType.AMEX]: {
      enabled: !!businessRisk.amex,
      value: businessRisk.amex ?? "",
    },
    [IntegrationType.RESURS]: {
      enabled: !!businessRisk.resurs,
      value: businessRisk.resurs ?? "",
    },
  });

  const primary = useMemo(() => {
    return (associates as Associate[]).find(
      (associate) =>
        associate.associateId === contract.contractViewer.associateId
    );
  }, [associates, contract]);

  const [invoiceEmail, setInvoiceEmail] = useState<string | undefined>(
    contract.contractData.invoiceEmail || primary?.contact.email
  );

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceEmailStatus>(
    getInitialStatus(contract, primary)
  );

  const [data, setData] = useState<FinancialSave>(response);

  const [hasSameInvoiceAddress, setHasSameInvoiceAddress] = useState<boolean>(
    lodashEqual(
      contract.contractData.address,
      contract.contractData.invoiceAddress
    )
  );
  const [isEmailInvoice, setIsEmailInvoice] = useState<InvoiceType | undefined>(
    getInitialInvoiceType(contract)
  );

  const [invoiceAddress, setInvoiceAddress] = useState<Address>(
    hasSameInvoiceAddress
      ? defaultAddress
      : { ...defaultAddress, ...contract.contractData.invoiceAddress }
  );

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([
      dataFinancial(access).getFinancialKey(linkId),
      dataBusinessRisk(access).getBusinessRiskKey(linkId),
    ]);
  }, [linkId, access, queryClient]);

  const {
    mutateAsync: saveIntegrations,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationError,
    reset: resetIntegration,
  } = useMutation(() => {
    return dataMerchant(access).saveIntegrations(
      linkId,
      getValuesFromIntegrations(integrations)
    );
  });

  const {
    mutateAsync: saveInvoice,
    isLoading: isInvoiceLoading,
    isError: isInvoiceError,
    reset: resetInvoice,
  } = useMutation(() => {
    if (!shouldSaveInvoice(contract.productType)) {
      return Promise.resolve();
    }

    if (isEmailInvoice === InvoiceType.EMAIL) {
      if (latestVerifiedInvoiceEmail.current !== invoiceEmail) {
        if (invoiceEmail === primary?.contact.email) {
          return dataFinancial(access).confirmPrimaryEmailAsInvoiceEmail(
            linkId
          );
        } else {
          return dataFinancial(access).confirmInvoiceVerificationCode(
            linkId,
            code
          );
        }
      }
    } else {
      if (hasSameInvoiceAddress) {
        return dataMerchant(access).saveInvoiceAddress(linkId, {
          ...contract.contractData.address,
        });
      } else {
        return dataMerchant(access).saveInvoiceAddress(linkId, {
          ...invoiceAddress,
        });
      }
    }

    return Promise.resolve();
  });

  const {
    mutateAsync: saveFinacial,
    isLoading: isFinacialLoading,
    isError: isFinacialError,
    reset: resetFinancial,
  } = useMutation(() =>
    dataFinancial(access).postFinancial(linkId, {
      ...data,
      thirdPartyPayments: false, // TODO this is just to pass backend validation. remove once solved
    })
  );

  const onSave = useCallback(() => {
    Promise.all([saveFinacial(), saveInvoice()]).then(() => {
      let invoice: Address;
      if (hasSameInvoiceAddress) {
        invoice = {
          ...contract.contractData.address,
        };
      } else {
        invoice = {
          ...invoiceAddress,
        };
      }
      setContract((prev) => {
        let contract = { ...prev };
        if (isEmailInvoice === InvoiceType.EMAIL) {
          contract = {
            ...prev,
            contractData: {
              ...prev.contractData,
              invoiceAddress: {},
              invoiceEmail,
            },
          };
        } else {
          contract = {
            ...prev,
            contractData: {
              ...prev.contractData,
              invoiceAddress: invoice,
              invoiceEmail: "",
            },
          };
        }
        return contract;
      });

      saveIntegrations().then(() => {
        invalidateCache();
        next();
      });
    });
  }, [
    saveFinacial,
    saveInvoice,
    saveIntegrations,
    next,
    invalidateCache,
    setContract,
    contract.contractData.address,
    hasSameInvoiceAddress,
    invoiceAddress,
    invoiceEmail,
    isEmailInvoice,
  ]);

  useEffect(() => {
    if (!isFinacialError && !isIntegrationError && !isInvoiceError) {
      return;
    }

    setTimeout(() => {
      resetIntegration();
      resetInvoice();
      resetFinancial();
    }, 6000);
  }, [
    isFinacialError,
    isIntegrationError,
    isInvoiceError,
    resetIntegration,
    resetFinancial,
    resetInvoice,
  ]);

  const minValidator = useMemo(
    () => getMinValidator(country, language as Language, data),
    [country, language, data]
  );

  const maxValidator = useMemo(
    () => getMaxValidator(country, language as Language, data),
    [country, language, data]
  );

  const hint = useMemo(
    () => getHint(country, language as Language, data),
    [country, language, data]
  );

  const validators = useMemo(() => {
    const v = [new RequiredValidator("Estimated annual turnover is required")];

    if (minValidator) {
      v.push(minValidator);
    }

    if (maxValidator) {
      v.push(maxValidator);
    }

    return v;
  }, [minValidator, maxValidator]);

  return (
    <>
      <Form
        className="flex-1"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (isEmailInvoice === InvoiceType.ADDRESS) {
            onSave();
            return;
          }

          if (
            latestVerifiedInvoiceEmail.current !== invoiceEmail &&
            primary?.contact.email !== invoiceEmail
          ) {
            setCodeWarning(true);
            return;
          }

          onSave();
        }}
        formContainer={formContainer}
      >
        <h2>
          <T>Financial information</T>
        </h2>

        <T>
          We would like to get to know your business in more detail and will
          therefore ask a few questions about your business.
        </T>

        <div className="m-top-40">
          <TextArea
            className={styles.desc}
            label="How would you describe your company's core business?"
            placeholder={t(
              'E.g. "Operates a lunch restaurant that sells home cooked food and drinks with à la carte in the evenings."'
            )}
            onChange={(value) => {
              setData((prev) => ({
                ...prev,
                businessModel: value,
              }));
            }}
            value={data.businessModel}
            validators={[
              new RequiredValidator("Value is required"),
              new MaxLengthValidator(
                MAX_LENGTH_DESCRIPTION,
                `Description must not exceed ${MAX_LENGTH_DESCRIPTION} characters`
              ),
            ]}
          />
        </div>

        <div className="m-top-10">
          <TextArea
            label="What are the most common goods/services your company sells?"
            placeholder={t('E.g. "We sell lunch with accompanying drink"')}
            onChange={(value) => {
              setData((prev) => ({
                ...prev,
                productDescription: value,
              }));
            }}
            value={data.productDescription}
            validators={[new RequiredValidator("Value is required")]}
          />
        </div>

        <hr />

        <div className="m-top-30">
          <EstimatedRangeFields
            onChange={(min, max) => {
              setData({
                ...data,
                estimatedRangeOfTransactionValueMin: min,
                estimatedRangeOfTransactionValueMax: max,
              });
            }}
            {...{ ...data }}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedTransactionsPerYear) =>
              setData({
                ...data,
                estimatedTransactionsPerYear,
              })
            }
            label="Estimated number of transactions per year regardless of payment method"
            value={data.estimatedTransactionsPerYear}
            placeholder={`${t("E.g.")} ${getIntlNumberFormat(language, 6000)}`}
            validators={[
              new RequiredValidator("Value is required"),
              new MinValidator(1, "Value must be positive"),
            ]}
            min={1}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedTransactionsByCardPerYear) =>
              setData({
                ...data,
                estimatedTransactionsByCardPerYear,
              })
            }
            label="Approximately how much of your turnover comes from card payments (%)?"
            value={data.estimatedTransactionsByCardPerYear}
            validators={[
              new RequiredValidator(
                "Estimated percentage of card transactions is required"
              ),
              new MinValidator(0, "Value can not be negative"),
              new MaxValidator(100, "Value can not be above 100%"),
            ]}
            suffix="%"
            min={0}
            max={100}
          />
        </div>

        <div className="m-top-20">
          <NumberInput
            onChange={(estimatedAnnualTurnover) =>
              setData({
                ...data,
                estimatedAnnualTurnover,
              })
            }
            label={"What is the total turnover of your company per year?"}
            value={data.estimatedAnnualTurnover}
            validators={validators}
            suffix={" " + CurrencyByCountry[country]}
            placeholder={`${t("E.g.")} ${getIntlNumberFormat(
              language,
              100000
            )} ${CurrencyByCountry[country]}`}
            min={1}
            hint={hint}
            disableCache={true}
          />
        </div>

        <Products productType={contract.productType}>
          <Products.Product
            products={[
              ProductType.BAMBORA_DEVICE,
              ProductType.BAMBORA_DEVICE_SHORT_TERM,
              ProductType.BAMBORA_ONE,
              ProductType.BAMBORA_ONE_SHORT_TERM,
            ]}
          >
            <hr />
            <InvoiceToggle
              isEmailInvoice={isEmailInvoice}
              setIsEmailInvoice={setIsEmailInvoice}
              address={contract.contractData.address}
              invoiceAddress={invoiceAddress}
              setInvoiceAddress={setInvoiceAddress}
              hasSameInvoiceAddress={hasSameInvoiceAddress}
              setHasSameInvoiceAddress={setHasSameInvoiceAddress}
              primary={primary}
              invoiceStatus={invoiceStatus}
              setInvoiceStatus={setInvoiceStatus}
              invoiceEmail={invoiceEmail}
              setInvoiceEmail={setInvoiceEmail}
              setCodeWarning={setCodeWarning}
              code={code}
              setCode={setCode}
              latestVerifiedInvoiceEmail={latestVerifiedInvoiceEmail}
              formContainer={formContainer}
            />
          </Products.Product>
        </Products>

        <hr />

        <Bax
          enableBax={integrations.bax.enabled}
          setEnableBax={(value) =>
            setIntegrations((prev) => ({
              ...prev,
              [IntegrationType.BAX]: {
                enabled: value,
                value: prev.bax.value,
              },
            }))
          }
          bax={integrations.bax.value}
          setBax={(value) =>
            setIntegrations((prev) => ({
              ...prev,
              [IntegrationType.BAX]: {
                enabled: prev.bax.enabled,
                value: value,
              },
            }))
          }
          contract={contract}
          businessRisk={businessRisk}
        />

        <Tof
          tof={integrations.tof.value}
          setTof={(value) =>
            setIntegrations((prev) => ({
              ...prev,
              [IntegrationType.TOF]: {
                enabled: prev.tof.enabled,
                value: value,
              },
            }))
          }
          contract={contract}
          businessRisk={businessRisk}
        />

        <div className="m-bottom-10">
          <AmexAndResurs
            type="amex"
            enable={integrations.amex.enabled}
            setEnable={(value) =>
              setIntegrations((prev) => ({
                ...prev,
                [IntegrationType.AMEX]: {
                  enabled: value,
                  value: prev.amex.value,
                },
              }))
            }
            value={integrations.amex.value}
            setValue={(value) =>
              setIntegrations((prev) => ({
                ...prev,
                [IntegrationType.AMEX]: {
                  enabled: prev.amex.enabled,
                  value: value,
                },
              }))
            }
          />
        </div>
        <div className="m-bottom-10">
          <AmexAndResurs
            type="resurs"
            enable={integrations.resurs.enabled}
            setEnable={(value) =>
              setIntegrations((prev) => ({
                ...prev,
                [IntegrationType.RESURS]: {
                  enabled: value,
                  value: prev.resurs.value,
                },
              }))
            }
            value={integrations.resurs.value}
            setValue={(value) =>
              setIntegrations((prev) => ({
                ...prev,
                [IntegrationType.RESURS]: {
                  enabled: prev.resurs.enabled,
                  value: value,
                },
              }))
            }
          />
        </div>

        <hr />
        <div
          style={{
            padding: "20px 0 0 0",
          }}
        />
        <ButtonPaneWithLabel
          label="Is a license required to run your business?"
          value={data.licensedEntity}
          onChange={(value) => {
            setData((prev) => ({
              ...prev,
              licensedEntity: value ?? false,
            }));
          }}
          small
          buttons={[
            {
              text: "Yes",
              active: data.licensedEntity === true,
              data: true,
            },
            {
              text: "No",
              active: data.licensedEntity === false,
              data: false,
            },
          ]}
          validators={[new RequiredValidator("Required to answer")]}
        />

        <div className="small m-bottom-10">
          <InfoBox>
            <T>
              Examples of businesses that require a license are: dentists, taxis
              and casinos.
            </T>
          </InfoBox>
        </div>

        <AnimateHeightMotion presence>
          {data.licensedEntity && (
            <Section highlighted>
              <TextInput
                label="Name of the licensing body"
                value={data.licensingBodyName}
                onChange={(value) =>
                  setData((prev) => ({
                    ...prev,
                    licensingBodyName: value,
                  }))
                }
                validators={[
                  new RequiredValidator("Name of licensing body is required"),
                ]}
              />
            </Section>
          )}
        </AnimateHeightMotion>

        <div className="m-top-40">
          <Dynamic name={isIntegrationError || isFinacialError ? "one" : "two"}>
            {isIntegrationError || isFinacialError ? <GenericError /> : null}
          </Dynamic>

          <AnimateHeight name={isInvoiceError ? "error" : ""}>
            {isInvoiceError ? (
              isEmailInvoice === InvoiceType.EMAIL &&
              invoiceEmail !== primary?.contact.email &&
              !code ? (
                <GenericError>
                  <T>
                    Something went wrong. Seems like we are missing the
                    verfication code to the invoice email? Try adding the code
                    and make another try.
                  </T>
                </GenericError>
              ) : (
                <GenericError />
              )
            ) : (
              <div />
            )}
          </AnimateHeight>
        </div>

        <div>
          <AnimateHeight name={codeWarning ? "warn" : "no-warn"}>
            <div>
              {codeWarning ? (
                <ErrorBox relative>
                  <T>
                    You must confirm the invoice email by entering the requested
                    code
                  </T>
                  <p />
                </ErrorBox>
              ) : (
                <div />
              )}
            </div>
          </AnimateHeight>
        </div>

        <div className="m-top-20">
          <StoryContinueButton
            type="submit"
            disabled={access === Access.VIEW}
            isLoading={
              isIntegrationsLoading || isInvoiceLoading || isFinacialLoading
            }
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
